import React from 'react'
import circle from "../../image/arror_1.jpg"
import img_1 from "../../image/Options_img_1.png"
import vedio_1 from "../../image/options_vedio_1.mp4"
import vedio_2 from "../../image/options_vedio_2.mp4"
import vedio_3 from "../../image/options_vedio_3.mp4"
import vedio_4 from "../../image/options_vedio_4.mp4"
import img_2 from "../../image/Options_img_2.png"
import img_3 from "../../image/Options_img_3.png"
import img_4 from "../../image/Options_img_4.png"
import img_5 from "../../image/Options_img_5.png"
import img_6 from "../../image/Options_img_6.png"
import img_7 from "../../image/Options_img_7.png"



const PickOptions = () => {
  return (
    <>
       <div className='Create mt-5 mb-5'>
    <div className="w-85">
        <div className="row">
          <div className="col-lg-6 col-md-6">
                <div className="card_create   text-start">
                    <h1 className='title_heading_1'>Options for you</h1>
                    <p className='text-start plan'>For Dec 12 starting 10 AM</p>
                </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 justify-content-end d-flex">
               <div className="circle col-lg-3 col-sm-12 col-md-6 m-auto m-auto-lg-0 ">
                  <img src={circle} alt="" className='arror_1' />
               </div>
          </div>
        </div>
    </div>
</div>
<div className='options Curated'>
  <div className="w-85">
    <div className="row">
        <div className="col-lg-4 col-sm-12">
            <div className='row'>
                <div className="col-lg-4 col-md-4">
                    <div className="options_img">
                        <img src={img_1} alt="" className='w-100  ' />
                    </div>
                </div>
                <div className="col-lg-8 col-md-8">
                <video className='w-100 h-100'  autoPlay muted>
                <source src={vedio_1} type="video/mp4" />
                </video>

                </div>
            </div>
            <div className='row mt-3'>
                <div className="col-lg-6 col-md-6 ">
                    <div className='options_img_1 '>
                        <img src={img_2} alt="" className='w-100  ' />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-2 mt-lg-0">
                <div className='options_img_1'>
                        <img src={img_3} alt="" className='w-100' />
                    </div>
                </div>
            </div>
          
        </div>
        <div className="col-lg-4 col-md-12">
        <div className='row '>
                <div className="col-lg-6 col-md-6 mt-2 mt-lg-0 ">
                <video className='w-100 '  autoPlay muted>
                <source src={vedio_2} type="video/mp4" />
                </video>
                </div>
                <div className="col-lg-6 col-md-6 mt-2 mt-lg-0">
                <div className='options_img_1'>
                        <img src={img_4} alt="" className='w-100 ' />
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
            <div className="col-lg-8 col-md-8">
                <video className='w-100 h-100'  autoPlay muted>
                <source src={vedio_3} type="video/mp4" />
                </video>

                </div>
                <div className="col-lg-4 col-md-4">
                <video className='w-100 h-100'  autoPlay muted loop>
                <source src={vedio_4} type="video/mp4"  />
                </video>
                </div>
               
            </div>
        </div>
        <div className="col-lg-4 col-md-12">
            <div className='row'>
                <div className="col-lg-4 col-md-4">
                    <div className="options_img">
                        <img src={img_5} alt="" className='w-100  ' />
                    </div>
                </div>
                <div className="col-lg-8 col-md-8 mt-2 mt-lg-0">
                <div className="options_img">
                        <img src={img_6} alt="" className='w-100  ' />
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className="col-lg-6 col-md-6 ">
                    <div className='options_img_1 '>
                        <img src={img_2} alt="" className='w-100 imge ' />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-2 mt-lg-0">
                <div className='options_img_1'>
                        <img src={img_2} alt="" className='w-100' />
                    </div>
                </div>
            </div>
          
        </div>
    </div>
  </div>
</div>
    </>
  )
}

export default PickOptions