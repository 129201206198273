import React from 'react'
import circle from "../../image/circle.jpg"
import { FaLongArrowAltRight } from "react-icons/fa";
const Create = () => {
  return (
    <>
        <div className='Create mt-5 Curated '>
        <div className="w-85">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card_create     text-start">
                        <h1 className='title_heading_1'>Sabha Hopping</h1>
                        <p className='text-start plan'>I plan to start my day on Dec 12 with ...</p>
                    </div>
              </div>
              <div className="col-lg-6 col-md-6  justify-content-end d-flex">
                   <div className="circle col-lg-4 col-md-7 col-sm-8">
                      <img src={circle} alt=""  />
                   </div>
              </div>
            </div>
        </div>
    </div>
    <div className='artist Curated '>
      <div className="w-85">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 ">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'>Artist at Sabha 1 at 9.00 </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mt-lg-0 mt-md-0">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'>Artist 2 at Sabha 3 at 10.00 </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mt-lg-0 ">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'>Artist 4 at Sabha 5 at 16.00 </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
        </div>
        <div className="row mt-lg-5 mt-2">
          <div className="col-lg-4 col-md-6 col-sm-12">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'> Artist 12 at Sabha 3 at 14.00</p>
                <FaLongArrowAltRight/>
             </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mt-lg-0 mt-md-0">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'>Artist 3 at Sabha 8 at 10.00 </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mt-lg-0">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'> Choose from list </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
        </div>
      </div>
    </div>
    </>

  )
}

export default Create