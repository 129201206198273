import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Navbar from './Components/navbar/Navbar';
import Banner from './Components/banner/Banner';
import Create from './Components/Create/Create';
import PickOptions from './Components/PickOptions/PickOptions';
import Choose from './Components/Choose/Choose';
import Choose_1 from './Components/Choose_1/Choose_1';
import Choose_2 from './Components/Choose_2/Choose_2';
import Choose_3 from './Components/Choose_3/Choose_3';
import Choose_4 from './Components/choose_4/Choose_4';
import Footer from './Components/footer/Footer';
import Home from './Components/Home/Home';

function App() {
  return (
    <div className="App">
        {/* <Navbar />
        <Banner/>
        <Create id="creat"/>
        <PickOptions/>
       <Choose/>
       <Choose_1/>
       <Choose_2/>
       <Choose_3/>
       <Choose_4/>
       <Footer/> */}
       <Home />
    </div>
  );
}

export default App;
