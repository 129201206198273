import React from 'react'
import img_1 from "../../image/Options_img_1.png"
import img_2 from "../../image/Options_img_7.png"
import img_3 from "../../image/Options_img_8.png"
import pluse from "../../image/pluse.jpg"
const Choose_3 = () => {
  return (
    <>
       <div className='Create mt-5 mb-5'>
    <div className="w-85">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="card_create     text-start">
                    <h1 className='title_heading_1'>Sabha Hopping</h1>
                    <p className='text-start plan'>Here is your itinerary. Add food or ride options.</p>
                </div>
              
             
          </div>
          
        </div>
    </div>
</div>
<div className='Curated Curated'>
        <div className="w-90">
            <div className="row ">
                <div className="col-lg-4 col-md-6 col-sm-12 d-flex  align-items-center ">
                    <div className="pluse  ">
                        <img src={pluse} alt="" width={40}/>
                    </div>
                    <div className="list_img pluse_icon ">
                        <img src={img_1} alt="" />
                        <p className='text-start lists_chose'>Abhishek Ravishankar Mylapore Fine Arts</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 d-flex  align-items-center mt-3 mt-lg-0">
                    <div className="pluse  ">
                        <img src={pluse} alt="" width={40}/>
                    </div>
                    <div className="list_img pluse_icon ">
                        <img src={img_2} alt="" />
                        <p className='text-start lists_chose'>Veena Concert Brahma <br/>Gana Sabha</p>
                    </div>
                    
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 d-flex  align-items-center  mt-3 mt-lg-0">
                    <div className="pluse  ">
                        <img src={pluse} alt="" width={40}/>
                    </div>
                    <div className="list_img pluse_icon ">
                        <img src={img_3} alt="" />
                        <p className='text-start lists_chose'>Saketharaman Sivagami Pettachi Auditorium</p>
                    </div>
                    
                </div>
                {/* <div className="col-lg-2  d-flex  align-items-center ">
                    <div className="pluse  ">
                        <img src={pluse} alt="" width={40}/>
                    </div>
    
                    
                </div> */}
           
             
              
               
            </div>
        </div>
    </div>
    </>
 
  )
}

export default Choose_3