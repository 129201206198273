import React from 'react'
import circle from "../../image/circle.jpg"
import { FaLongArrowAltRight } from "react-icons/fa";
import img_1 from "../../image/Options_img_1.png"
import img_2 from "../../image/Options_img_7.png"
import img_3 from "../../image/Options_img_8.png"
const Choose_2 = () => {
    return (
        <>
        <div className='Create mt-5 mb-5'>
        <div className="w-85">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card_create     text-start">
                        <h1 className='title_heading_1'>Sabha Hopping</h1>
                        <p className='text-start plan'>Choose your next concert ...</p>
                    </div>
                    <div className='row'>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-5">
                        <div className="list_img">
                            <img src={img_1} alt="" />
                            <p className='text-start lists_chose'>Abhishek Ravishankar</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-5">
                        <div className="list_img">
                            <img src={img_2} alt="" />
                            <p className='text-start lists_chose'>Veena Concer</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-5">
                        <div className="list_img">
                            <img src={img_3} alt="" />
                            <p className='text-start lists_chose'>Saketharaman</p>
                        </div>
                    </div>
                    </div>
                 
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 justify-content-end d-flex mt-5 mt-lg-0">
                   <div className="circle col-lg-4 col-md-7">
                      <img src={circle} alt="" />
                   </div>
              </div>
            </div>
        </div>
    </div>
    <div className='artist Curated '>
      <div className="w-85">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'> <a href="#">Artist at Sabha 1 at 9.00</a> </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mt-lg-0 mt-md-0">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'>Artist 2 at Sabha 3 at 10.00 </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mt-lg-0">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'>Artist 4 at Sabha 5 at 16.00 </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
        </div>
        <div className="row mt-lg-5 mt-2">
          <div className="col-lg-4 col-md-6 col-sm-12 ">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'> Artist 12 at Sabha 3 at 14.00</p>
                <FaLongArrowAltRight/>
             </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mt-lg-0 mt-md-0">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'>Artist 3 at Sabha 8 at 10.00 </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2 mt-lg-0">
             <div className="card_art d-flex align-items-center  justify-content-evenly">
                <p className='artist_para'> Choose from list </p>
                <FaLongArrowAltRight/>
             </div>
          </div>
        </div>
      </div>
    </div>
    </>
      )
}

export default Choose_2