import React, { useState } from 'react'
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
const Navbar = () => {

    const [isMobile, setIsMobile] = useState(false);
  return (
    <nav className="navbar">
      <ul className={isMobile ? "nav-links-mobile" : "nav-links"}>
        <li><a href="#">Home Page</a></li>
        <li><a href="#">Create</a></li>
        <li><a href="#">Pick Options</a></li>
        <li><a href="#">Create 1</a></li>
        <li><a href="#">Create 1</a></li>
        <li><a href="#">Create 1</a></li>
        <li><a href="#">Create 1</a></li>
        <li><a href="#">Create 1</a></li>
      </ul>
      <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
        {isMobile ? <IoCloseSharp/> : <FaBars/>}
      </button>
    </nav>

  )
}

export default Navbar