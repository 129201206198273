import React, { useRef, useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import Banner from "../banner/Banner";
import Create from "../Create/Create";
import PickOptions from "../PickOptions/PickOptions";
import Choose from "../Choose/Choose";
import Choose_1 from "../Choose_1/Choose_1";
import Choose_2 from "../Choose_2/Choose_2";
import Choose_3 from "../Choose_3/Choose_3";
import Choose_4 from "../choose_4/Choose_4";


const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  const listRef = useRef(null);
  
  // Function to handle menu item click
  const handleMenuItemClick = () => {
    if (isMobile) {
      setIsMobile(false); // Close the menu only if it's in mobile view
    }
  };

  return (
    <div>
      <nav className="navbar">
        <ul className={isMobile ? "nav-links-mobile" : "nav-links"} ref={listRef}>
          <li onClick={handleMenuItemClick}>
            <a href="#home">Home Page</a>
          </li>
          <li onClick={handleMenuItemClick}>
            <a href="#create">Create</a>
          </li>
          <li onClick={handleMenuItemClick}>
            <a href="#pick-options">Pick Options</a>
          </li>
          <li onClick={handleMenuItemClick}>
            <a href="#choose">Create 1</a>
          </li>
          <li onClick={handleMenuItemClick}>
            <a href="#choose_1">Create 1</a>
          </li>
          <li onClick={handleMenuItemClick}>
            <a href="#choose_2">Create 1</a>
          </li>
          <li onClick={handleMenuItemClick}>
            <a href="#choose_3">Create 1</a>
          </li>
          <li onClick={handleMenuItemClick}>
            <a href="#choose_4">Create 1</a>
          </li>
        </ul>
        <button
          className="mobile-menu-icon"
          onClick={() => setIsMobile(!isMobile)}
        >
          {isMobile ? <IoCloseSharp /> : <FaBars />}
        </button>
      </nav>
 
      <div id="home"  className="pt-5"> {<Banner />}  </div>
     
      <div id="create" className="pt-5">  {<Create />}</div>
      <div id="pick-options"  className="pt-5"> {<PickOptions />} </div>
      <div id="choose"  className="pt-5"> {<Choose />}  </div>
      <div id="choose_1" className="pt-5" >  {<Choose_1 />}   </div>
      <div id="choose_2" className="pt-5" >  {<Choose_2 />}   </div>
      <div id="choose_3"  className="pt-5">    {<Choose_3 />} </div>
      <div id="choose_4" className="pt-5" > {<Choose_4 />}</div>
     
 
    
    </div>
  );
};

export default Home;
