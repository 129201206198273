import React from 'react'
import square from "../../image/square.png"
import arror from "../../image/arrow.png"
import img_1 from "../../image/img_1.png"
import img_2 from "../../image/img_2.png"
import img_3 from "../../image/img_3.png"
import img_4 from "../../image/img_4.png"
const Banner = () => {
  return (
    < >
  <div className='banner '>
        <div className="w-90">
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-sm-12 d-flex mt-5 h-100">
                    <div className="card_logo d-flex ">
                       <img src={square} alt=""className='logo' />
                       <p className='m-0 c_nmae ps-3'>MuZeekh</p>
                    
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-12 mt-2 d-flex ">
                    <div className="card-heading">
                        <div className='d-flex align-items-center gaps'  >
                        <h1 className='main_heading'>Sabha</h1>
                        <img src={arror} alt=""  className='arror'/>
                        </div>
                      <div>
                      <h1 className='main_heading '>Hopping</h1>
                      </div>
                       
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mt-5">
                     <div className="get_start w-75 text-center">
                        <a href="#">Get Started</a>
                     </div>
                </div>
            </div>
           
        </div>
    </div>
    <div className='Curated List'>
        <div className="w-90">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 mt-3  ">
                    <div className="list_img">
                        <img src={img_1} alt="" />
                        <p className='text-start lists'>Curated List #1</p>
                    </div>
                  
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mt-3 ">
                    <div className="list_img">
                        <img src={img_2} alt="" />
                        <p className='text-start lists'>Curated List #1</p>
                    </div>
                  
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mt-3 ">
                    <div className="list_img">
                        <img src={img_3} alt="" />
                        <p className='text-start lists'>Curated List #1</p>
                    </div>
                  
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mt-3 ">
                    <div className="list_img">
                        <img src={img_4} alt="" />
                        <p className='text-start lists'>Curated List #1</p>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
    </>
  
  )
}

export default Banner